import { Layout } from '../../components/layout'
import { HeadFC } from 'gatsby'
import { Container } from '../../components/layout/Container/Container'
import * as pageStyles from './styles/legal-terms.page.module.scss'
import * as globalStyles from './../../styles/global.module.scss';
import { SiteLegalTermsData } from '../../data'
import SectionHero from "../../components/sections/hero";
import ImageHeader from './images/main-pic.svg';
import csecIcon from './images/csec.png';


const sectionData = {
    title: 'Regulated trading services',
    subtitle: 'We are committed to providing our clients around the world with high-quality trading services.We are client-focused, constantly striving to improve our offering, while taking all necessary steps to create the best possible trading environment. For more information, please see our',
    ctaText: 'Contact us',
    linkPath: '/contacts#contactsForm',
    image: ImageHeader,

}

function SectionLegalTermsDocumentsList() {
    return (
        <section>
            <Container className={pageStyles.legalTermsDocumentsSection}>
                <div className={pageStyles.legalTermsDocumentsSectionHeading}>
                    <h2>Legal documents and disclosures</h2>
                </div>
                <ul className={pageStyles.legalTermsDocumentsGrid}>
                    {SiteLegalTermsData.map((item, key) => {
                        return (
                            <li key={key} style={{order: key + 1 }} data-position={item.position}>
                                <a href={item.documentFileLink} target='_blank' >{item.documentName}</a>
                            </li>
                        )
                    })}
                </ul>
            </Container>
        </section>
    )
}


const LegalTermsPage = () => {
    return (
        <Layout>
            <SectionHero title={sectionData.title} subtitle={sectionData.subtitle} ctaText={sectionData.ctaText} linkPath={sectionData.linkPath} image={sectionData.image} linkEndPText={'Order Execution Policy'} linkEndP={'/documents/Order-Execution-Policy.pdf'}/>
            <SectionCSEC/>
            <SectionLegalTermsDocumentsList />
        </Layout>
    )
}

const SectionCSEC = () => {
    return (
        <section className={pageStyles.section}>
            <Container>
                <div className={pageStyles.card}>
                    <div className={pageStyles.firstBlock}>
                        <div
                            // className={pageStyles.imgWrapper}
                        >
                            <img src={csecIcon} alt="" width={177} height={64}/>
                        </div>
                        <h4>Cyprus Securities and Exchange Comission (CySEC)</h4>
                    </div>
                    <div className={pageStyles.pBlock}>
                        <p>CySEC is the independent public supervisory authority responsible for the supervision of the investment services in Cyprus. </p>
                        <p>CTS Cloud Trading Solutions Ltd is a Cyprus Investment Firm, authorized and regulated by the Cyprus Securities and Exchange Commission (CySEC) with license number 224/14.</p>
                        <p>Learn more about our CySEC license <a href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/37630/" target='_blank' className={globalStyles.link}>here</a>.</p>
                    </div>
                </div>

            </Container>
        </section>
    )
}


const Head: HeadFC = () => (
    <>
        <title>Regulated trading services</title>
        <meta name="description" content="We are committed to providing our clients around the world with high-quality trading services. We are client-focused, constantly striving to improve our offering, while taking all necessary steps to create the best possible trading environment." />
    </>
)

export { LegalTermsPage, Head }
